import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";
import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

function* destroy(action: AnyAction): any {
    const response = yield call(request, api.destroy, action, "delete");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.translate.saga.content.destroy"),
            })
        );
    }
}

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* store(action: AnyAction): any {
    const response = yield call(request, api.store, action, "post");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.translate.saga.content.store"),
            })
        );
    }
}

function* google(action: AnyAction): any {
    const response = yield call(request, api.google, action, "post");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.translate.saga.content.google"),
            })
        );
    }
}

function* toService(action: AnyAction): any {
    const response = yield call(request, api.toService, action);

    if (response.status === 202) {
        yield put(
            snackbarShow({
                content: trans("admin.translate.saga.content.toService"),
            })
        );
    }
}

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.translate.saga.content.update"),
            })
        );
    }
}

function* patch(action: AnyAction): any {
    const response = yield call(request, api.patch, action, "patch");
    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.translate.saga.content.update"),
            })
        );
    }
    if (response.status === 422) {
        yield put(
            snackbarShow({
                content: response.data.message,
                severity: "error",
                errors: response.data.errors
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.DESTROY_REQUEST, destroy);
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.PATCH_REQUEST, patch);
    yield takeLatest(types.TRANSLATE_GOOGLE_REQUEST, google);
    yield takeLatest(types.STORE_REQUEST, store);
    yield takeLatest(types.TRANSLATE_TO_SERVICE_REQUEST, toService);
    yield takeLatest(types.UPDATE_REQUEST, update);
}
