import { modalHide } from "app/App/actions";
import { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Checkbox,
    Form,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
    TextField,
} from "ui";
import { IAddTranslation } from "../interfaces";
import useRequest from "api/useRequest";
import { Box, Grid } from "@mui/material";
import trans from "helpers/trans";
import { IGPFormRef } from "ui/Form/Form";
import { AxiosResponse } from "axios";
import { translateCreate, translateUpdate } from "../actions";
import { isEmpty } from "lodash";
import { getProject } from "../config";

const AddTranslationModal: FC<IAddTranslation> = ({
    data,
    id,
    onNewSuccess,
    onSuccess,
}) => {
    const project = getProject();
    const dispatch = useDispatch();
    const formRef = useRef<IGPFormRef | null>();

    const { errors, isLoading, message, request, status } = useRequest();
    const {
        errors: updateErrors,
        isLoading: updateIsLoading,
        message: updateMessage,
        request: updateRequest,
        status: updateStatus,
    } = useRequest();
    // const {
    //     errors: googleErrors,
    //     isError: googleIsError,
    //     isLoading: googleIsLoading,
    //     request: googleRequest,
    //     isPending: googleIsPending,
    //     status: googleStatus,
    // } = useRequest();

    const handleClose = () => {
        dispatch(modalHide("AddTranslationModal"));
    };
    const handleTranslation = (reload: Function) => {
        if (formRef.current !== null) {
            const dataToSend = formRef?.current?.getData();
            if (id)
                updateRequest(
                    translateUpdate(
                        id,
                        project,
                        dataToSend,
                        (response: AxiosResponse) => {
                            if (response.status === 204) {
                                reload();
                            }
                        }
                    )
                );
            else
                request(
                    translateCreate(
                        project,
                        dataToSend,
                        (response: AxiosResponse) => {
                            if (response.status < 300) {
                                reload();
                            }
                        }
                    )
                );
        }
    };
    const handleSaveNewTranslation = () => {
        handleTranslation(() => {
            handleClose();
            onNewSuccess();
            if (formRef.current) {
                const data = formRef.current.getData();
                formRef.current.refreshFormData({
                    key: data.key.replace(/\.[^.]*$/, ""),
                    lang_en: "",
                    lang_nb: "",
                });
            }
        });
    };

    const handleSaveTranslation = () => {
        handleTranslation(() => {
            handleClose();
            onSuccess();
        });
    };

    // const handleGoogleTranslate = () => {
    //     if (formRef === undefined) {
    //         alert("Something went wrong");
    //     }
    //     googleRequest(translateGoogle())
    // };

    return (
        <Modal open maxWidth="md" fullWidth>
            <ModalTitle onClose={handleClose}>
                {trans("admin.translate.title")}
            </ModalTitle>
            <ModalContent>
                <RequestMessage
                    status={
                        updateStatus >= 400 || status >= 400
                            ? Math.max(updateStatus, status)
                            : 0
                    }
                    message={
                        updateMessage ? updateMessage : message ? message : ""
                    }
                    sx={{
                        mb: 1,
                    }}
                />
                <Form
                    ref={formRef}
                    data={data as any}
                    errors={
                        !isEmpty(errors)
                            ? errors
                            : !isEmpty(updateErrors)
                            ? updateErrors
                            : {}
                    }
                    fields={{
                        key: {},
                        lang_en: {},
                        lang_nb: {},
                        approved: { default: false },
                    }}
                    loading={isLoading || updateIsLoading}
                    onSubmit={() => {}}
                    unsaved={false}
                >
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                autoFocus
                                id="key"
                                label={trans(
                                    "admin.translate.modal.addTranslationModal.key"
                                )}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Button
                                onClick={handleGoogleTranslate}
                                startIcon={<Icon>translate</Icon>}
                                variant="text"
                            >
                                Google
                            </Button>
                        </Grid>
                    </Grid> */}

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="lang_en"
                                fullWidth
                                label={trans(
                                    "admin.translate.modal.addTranslationModal.en"
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="lang_nb"
                                fullWidth
                                label={trans(
                                    "admin.translate.modal.addTranslationModal.nb"
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container mb={2}>
                        <Grid item xs={12}>
                            <Checkbox
                                id="approved"
                                label={trans(
                                    "admin.translate.modal.addTranslationModal.approved"
                                )}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </ModalContent>
            <ModalActions>
                <Box
                    display="flex"
                    width="100%"
                    textAlign="right"
                    gap={1}
                    justifyContent="end"
                >
                    <Button
                        onClick={handleClose}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <LoadingButton
                        loading={isLoading || updateIsLoading}
                        variant="outlined"
                        color="secondary"
                        onClick={handleSaveNewTranslation}
                    >
                        {trans("global.button.saveNew")}
                    </LoadingButton>
                    <LoadingButton
                        loading={isLoading || updateIsLoading}
                        variant="outlined"
                        color="secondary"
                        onClick={handleSaveTranslation}
                    >
                        {trans("global.button.save")}
                    </LoadingButton>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default AddTranslationModal;
