import LocationsTableReducer from "./reducers/LocationsTableReducer";
import LocationsSaga from "./saga";

import IConfig from "interfaces/IConfig";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "inventory.locations";
export const url = "locations";
export const groupsUrl = `${url}/groups`;

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add(
            "InventoryLocationTable",
            LocationsTableReducer
        );
        store.injectSaga?.("LocationsSaga", LocationsSaga);
        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Inventory/Locations/pages/LocationsIndexPage" />
            ),
        });
    }

    // if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
    //     config.permissions.create = true;

    //     routing.push({
    //         path: `${url}/create`,
    //         element: (
    //             <AsyncComponent path="Inventory/Locations/pages/LocationsCreatePage" />
    //         ),
    //     });
    // }
    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="Inventory/Locations/pages/LocationsEditPage" />
            ),
        });
    }
    // if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
    //     config.permissions.delete = true;
    // }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;
